import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function CKEditorComp(props) {
  return (
    <div style={{ margin: 20 }}>
      <div style={{ fontSize: 30, fontWeight: "700" }}>{props.title}</div>
      <CKEditor
        editor={ClassicEditor}
        config={{ model: "normal", view: null, title: "normal" }}
        data={props.type == "add" ? "" : props.data}
        onChange={(event, editor) => {
          const data = editor.getData();
          props.stateData(data);
          console.log({ event, editor, data });
        }}
        onBlur={(event, editor) => {
          console.log("Blur.", editor);
        }}
        onFocus={(event, editor) => {
          console.log("Focus.", editor);
        }}
      />
    </div>
  );
}
