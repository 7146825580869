import React from "react";
import { ReactComponent as JPGPNGIcon } from "../img/svg/JPGPNG.svg";
import { ReactComponent as DeleteIcon } from "../img/svg/delete.svg";
import { Line } from "rc-progress";
import {
  Accordion,
  Row,
  Col,
  Container,
  Image,
  Button,
  Form,
  FormCheck,
  InputGroup,
} from "react-bootstrap";

export default function Fileprogressbar(props) {
  let num = props.num;

  return (
    <Container style={{ paddingTop: 26 }}>
      <Row>
        <Col
          className="d-flex justify-content-center align-items-center"
          sm="auto"
          xs="auto"
        >
          <JPGPNGIcon className="JPGPNGIcon" />
        </Col>

        <Col
          className="d-flex flex-column"
          style={{ width: 844, height: "auto" }}
        >
          <Row>
            <Col className="d-flex flex-row justify-content-between">
              <p className="Filename">{props.fileName}</p>
              <p className="progresstext">Completed</p>
            </Col>
          </Row>
          <Line percent="100" trailColor="#E0E0E0" strokeColor="#7D6746" />
        </Col>

        <Col
          className="d-flex justify-content-center align-items-center"
          sm="auto"
          xs="auto"
        >
          <DeleteIcon onClick={props.delect} className="DeleteIcon" />
        </Col>
      </Row>
    </Container>
  );
}
