import React from "react";
import { ReactComponent as FooterIcon } from "../img/svg/footer-icon.svg";
import { ReactComponent as FootermapIcon } from "../img/svg/footer-icon-map.svg";
import { ReactComponent as FootercontactIcon } from "../img/svg/footer-icon-contact.svg";
import { ReactComponent as FooterfaxIcon } from "../img/svg/footer-icon-fax.svg";
import { ReactComponent as FooteremailIcon } from "../img/svg/footer-icon-email.svg";
import "../styles/footer.css";
import { Col, Container, Row } from "react-bootstrap";

export default function Footer() {
  return (
    
    <div className="footer-bg-color">
      
      <Container>
        <Row>
          <Col xs={{ span: 10, offset: 1 }} sm={{span:12, offset: 0}} lg="4" xxl="4">
            <div
                className="header"
            >
                <FooterIcon className="LUAicon" />
                <p style={{ marginLeft: 16, marginTop: 13}}>Directors’ Club</p>
            </div>
            <p className="content">
              <p>
            The usage right of Directors’ Club logo or trademark, which includes but is not limited to printing and promotion.<br /> 
            Directors’ Club 標誌或商標的使用權，包括但不限於印刷和推廣。 <br />
            Incorporated by LUAHK
              </p>
            </p>
          
          </Col>

          <Col xs={{ span: 11, offset: 1 }} sm={{span:11, offset: 0}} md="8" lg="6" xxl="5">
          
            <div
                className="header"
            >
            <p style={{marginTop: 13}}>聯絡資料</p>    
            </div>
            <p className="content vertical">
              <Row>
                <Col>
                
                  <p className="iconplustext">
                    <Col sm="1" >
                      <FootermapIcon style={{ marginRight: 11,}} />
                    </Col>
                    <Col >
                      Unit A-D, 23/F., Seabright Plaza, 9~23 Shell Street, North Point, Hong Kong. <br /> 
                      香港北角蜆殼街9-23號秀明中心23樓A-D室
                    </Col>
                  </p>
                </Col>
              </Row>

              <Row>
                <Col>
                
                  <p className="iconplustext">
                  <Col sm="1" >
                    <FootercontactIcon  style={{ marginRight: 11}} />
                  </Col>
                  <Col>
                    2570 2256
                  </Col>
                  </p>
                </Col>
              </Row>

              {/* <div className="iconplustext">
                <FootermapIcon style={{ marginRight: 11}}/>
                <p style={{}}>
                  Unit A-D, 23/F., Seabright Plaza, 9~23 Shell Street, North Point, Hong Kong. <br /> 
                  香港北角蜆殼街9-23號秀明中心23樓A-D室
                </p>
              </div>

              <div className="iconplustext" >
                
                <p>
                <FootercontactIcon style={{ marginRight: 11}}/>
                  2570 2256
                </p>
              </div> */}
            
              
            </p>
          
          </Col>

        


          <Col xs={{ span: 11, offset: 1 }} sm={{span:11, offset: 0}} md="3" lg="2" xxl="2">
              
            <div
                className="headerlast"
            >
            <p></p>    
            </div>
            <p className="content">
              <Row>
                <Col>
                
                  <p className="iconplustext">
                  <Col sm="2.5">
                  <FooterfaxIcon style={{ marginRight: 11}} />
                  </Col>
                  <Col>
                  2570 1525
                  </Col>
                  </p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <p className="iconplustext">
                  <Col sm="2.5" >
                  <FooteremailIcon  style={{ marginRight: 11}} />
                  </Col>
                  <Col>
                  info@luahk.org
                  </Col>
                  </p>
                </Col>
              </Row>
            </p>

          </Col>

          <div class="text-center">
            <p className="Copyright" >Copyright © 2022 LUA Limited All rights reserved version1.1.</p>
          </div>   
        </Row>

      </Container>    
      
    </div>
  );
}