import React, { useEffect, useState } from "react";
import { Modal, Button, Container, Row, Col, Alert } from "react-bootstrap";
import CardInfo from "../components/CardInfo";
import { FAQData } from "../helper/tempData";
import "../styles/CardViewModal.css";
import CKEditorModal from "./CKEditorModal";
import ModalBtnRow from "./ModalBtnRow";
import OrderingModal from "./OrderingModal";
import PDFEditorModal from "./PDFEditorModal";
import axios from "../helper/axios";
import { LUA } from "../config";

export default function CardViewModal(props) {
  const [showCKEditor, setShowCKEditor] = useState(false);
  const [showPDFEditor, setShowPDFEditor] = useState(false);
  const [showOrdering, setShowOrdering] = useState(false);
  const [showAddFAQData, setShowAddFAQData] = useState(false);
  const [showAddPDFData, setShowAddPDFData] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [dataID, setDataID] = useState();

  const [CMSData, setCMSData] = useState(props.apiData);

  const [delData, setDelData] = useState([]);

  const getData = async () => {
    const luaToken = LUA.luaToken;
    let apiResponse;

    const post = {
      token: luaToken,
    };

    // if (FAQData.success) {
    //   setFapCMSData(FAQData.result);
    //   console.log(FAQData);
    // }

    if (props.location == "FAQ") {
      axios.post("/get_dc_qna.php", post).then((response) => {
        apiResponse = response.data;
        console.log(apiResponse);

        if (response.data.success) {
          setCMSData(response.data.result);
          console.log("success");
        }
      });
      return;
    }
    if (props.location == "dcmain") {
      axios.post("/get_dc_service.php", post).then((response) => {
        apiResponse = response.data;
        console.log(apiResponse);

        if (response.data.success) {
          setCMSData(response.data.result);
        }
      });
      return;
    }
  };

  const postData = (newData) => {
    const luaToken = LUA.luaToken;
    let apiResponse;

    const post = {
      token: luaToken,
      datas: newData,
    };

    console.log("post Data", post);

    if (props.location == "FAQ") {
      axios.post("/post_dc_qna.php", post).then((response) => {
        apiResponse = response.data;
        console.log(apiResponse);

        if (response.data.success) {
          setShowCKEditor(false);
          console.log("success");
          getData();
        } else {
          alert("Update error!");
        }
      });
    }
    if (props.location == "dcmain") {
      axios
        .post("/post_dc_service.php", post)
        .then((response) => {
          apiResponse = response.data;
          console.log(apiResponse);

          if (response.data.success) {
            setShowPDFEditor(false);
            console.log("success");
            getData();
          } else {
            alert(response.message);
          }
        })
        .catch((err) => {
          // console.log(err, "Error");
        });
    }
  };

  const fetchData = CMSData.map((array, index) => {
    if (CMSData.length > 0) {
      return (
        <Col xs={"auto"} key={index}>
          <CardInfo
            data={array}
            title={array.title}
            content={array.content}
            img={props.location === "dcmain" ? array.image : null}
            showData={(tf) => {
              setDataID(index);
              const temp = { ...CMSData[index], active: tf };
              postData([temp]);
            }}
            ckedit={() => {
              setDataID(index);
              if (props.location === "FAQ") {
                setShowCKEditor(true);
              }
              if (props.location === "dcmain") {
                setShowPDFEditor(true);
              }
            }}
            delete={() => {
              setDataID(index);
              const temp = { ...CMSData[index], deleted: true };
              console.log(temp);
              if (window.confirm("確定要刪除資料？")) {
                console.log("yes");
                postData([temp]);
              }
              getData();
            }}
          />
        </Col>
      );
    }
  });

  return (
    <Modal
      size="xl"
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 9999 }}
      show={props.show}
    >
      {showCKEditor && props.location === "FAQ" ? (
        <CKEditorModal
          postion={"FAQ"}
          type={"edit"}
          show={showCKEditor}
          handleClose={() => {
            getData();
            setShowCKEditor(false);
          }}
          data={CMSData[dataID]}
          postData={(newData) => {
            postData([newData]);
          }}
        />
      ) : null}
      {showPDFEditor && props.location === "dcmain" ? (
        <PDFEditorModal
          postion={"FAQ"}
          type={"edit"}
          show={showPDFEditor}
          handleClose={() => {
            getData();
            console.log("check");
            setShowPDFEditor(!showPDFEditor);
          }}
          data={CMSData[dataID]}
          postData={(newData) => {
            postData([newData]);
          }}
        />
      ) : null}
      {showOrdering ? (
        <OrderingModal
          show={showOrdering}
          data={CMSData}
          handleClose={() => {
            getData();
            setShowOrdering(!showOrdering);
          }}
          postData={(newData) => {
            postData(newData);
          }}
        />
      ) : null}
      {showAddFAQData && props.location === "FAQ" ? (
        <CKEditorModal
          postion={"FAQ"}
          type={"add"}
          show={showAddFAQData}
          handleClose={() => {
            getData();
            setShowAddFAQData(!showAddFAQData);
          }}
          postData={(newData) => {
            postData([newData]);
          }}
        />
      ) : null}
      {showAddPDFData && props.location === "dcmain" ? (
        <PDFEditorModal
          postion={"dcmain"}
          type={"add"}
          show={showAddPDFData}
          handleClose={() => {
            getData();
            setShowAddPDFData(false);
          }}
          postData={(newData) => {
            postData([newData]);
          }}
        />
      ) : null}
      <Modal.Body>
        <Container>
          <ModalBtnRow
            title={props.location == "FAQ" ? "問題" : "瀏覽"}
            numLength={CMSData.length}
            ordering={() => {
              setShowOrdering(!showOrdering);
            }}
            addData={() => {
              if (props.location === "FAQ") {
                setShowAddFAQData(!showAddFAQData);
              }
              if (props.location === "dcmain") {
                if (CMSData.length == 9) {
                  alert("記錄不能超過九項！");
                } else {
                  setShowAddPDFData(!showAddPDFData);
                }
              }
            }}
          />
          <Row>{fetchData}</Row>
          <div style={{ marginTop: "2vh", alignContent: "center" }}>
            共有{CMSData.length}條記錄
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          返回
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
