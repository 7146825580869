import React from "react";
import { Modal, Button } from "react-bootstrap";
import "../styles/modal.css";

export default function InfoModal(props) {
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>登入小幫手</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>例子: 如你的身份證號碼是 AB123456(3),</div>
        <div>
          請輸入你的身份證號碼到白色空格內，例如：第一的字母，第六，第七及第八的數字，即A,
          4, 5及6
        </div>
        <div className="div-input-name-mo">
          <div className="div-input-eng">
            <div>英文</div>
          </div>
          <div className="div-input-int-mo">
            <div className="div-int-font">數字</div>
          </div>
          <div className="div-input-brackets-mo">
            <div>括號</div>
          </div>
        </div>
        <div className="div-modal-input">
          <input
            className="input-box"
            type="text"
            maxlength="1"
            value={"A"}
            disabled
          ></input>
          <input
            className="input-box"
            type="text"
            maxlength="1"
            value={"B"}
            disabled
          ></input>
          <input
            className="input-box"
            type="text"
            maxlength="1"
            value={"1"}
            disabled
          ></input>
          <input
            className="input-box"
            type="text"
            maxlength="1"
            value={"2"}
            disabled
          ></input>
          <input
            className="input-box"
            type="text"
            maxlength="1"
            value={"3"}
            disabled
          ></input>
          <input
            className="input-box"
            type="text"
            maxlength="1"
            value={"4"}
            disabled
          ></input>
          <input
            className="input-box"
            type="text"
            maxlength="1"
            value={"5"}
            disabled
          ></input>
          <input
            className="input-box"
            type="text"
            maxlength="1"
            value={"6"}
            disabled
          ></input>
          <input
            className="input-box"
            type="text"
            maxlength="1"
            value={"7"}
            disabled
          ></input>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
