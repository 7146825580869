import React from "react";
import { Button, Row, Col } from "react-bootstrap";

export default function ModalBtnRow(props) {
  return (
    <Row>
      <Col style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "70vw" }}>共有{props.numLength}條記錄</div>
        <div
          style={{
            textAlign: "center",
            width: "30vw",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Button variant="secondary" onClick={props.ordering}>
            排序
          </Button>
          <Button variant="secondary" onClick={props.addData}>
            新增{props.title}
          </Button>
        </div>
      </Col>
    </Row>
  );
}
