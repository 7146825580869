import React, { useRef } from "react";
import "../styles/Verification.css";
import background from "../img/png/become_mem_background.png";
import { ReactComponent as ConfirmIcon } from "../img/svg/confirm.svg";
import { Accordion, Row, Col, Container, Image, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { memberInfo, DCnumbering } from "../helper/memberInfo";
import { useReactToPrint } from "react-to-print";
import { Config } from "../envConfig";

export default function Verification() {
  let navigate = useNavigate();
  const routeNavigate = () => {
    let path = "/";
    navigate(path);
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div className="Verification">
      <div
        className="bg"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "auto",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        <Container>
          <Row>
            <Col
              md={"4"}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <Image src={require("../img/png/S1white.png")} width={"100%"} />
            </Col>
            <Col
              md={"4"}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <Image src={require("../img/png/S2white-2.png")} width={"100%"} />
            </Col>
            <Col
              md={"4"}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <Image src={require("../img/png/S3brown.png")} width={"100%"} />
            </Col>
          </Row>
        </Container>
      </div>

      <div ref={componentRef} style={{ backgroundColor: "#F2EFEC" }}>
        <Container>
          <Row>
            <Col
              className="d-flex justify-content-center align-items-center"
              style={{ height: 354 }}
            >
              <ConfirmIcon />
            </Col>
          </Row>
          <Row>
            <Col
              className="d-flex justify-content-center"
              style={{ height: 300 }}
            >
              <p className="confirmtext">
                您已完成保協總監會會員之申請, 稍後保協將向您發出｢確認電郵｣,
                <br />
                待您收到此｢確認電郵｣, 您便會成為 總監會 會員
                <br />
                <div style={{ marginTop: 10 }}>
                  你的申請編號： {DCnumbering.DCnumbering}
                </div>
                <p className="confirmtext-eng">
                  You have completed the application for the membership of the
                  Directors' Club of LUAHK,
                  <br />
                  and LUAHK will send you a "Confirmation Email" later,
                  <br />
                  Once you receive this "Confirmation Email", you will become a
                  Director's Club member.
                  <br />
                  <div style={{ marginTop: 10 }}>
                    Your reference number: {DCnumbering.DCnumbering}
                  </div>
                </p>
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="div-btn">
        {/* print function */}
        <Button
          style={{
            width: 135,
            height: 48,
            backgroundColor: "#B39A77",
            border: 0,
            borderRadius: 8,
          }}
          className="btn"
          onClick={handlePrint}
        >
          <div>列印編號</div>
        </Button>
        <Button
          style={{
            width: 135,
            height: 48,
            backgroundColor: "#7D6746",
            border: 0,
            borderRadius: 8,
            marginLeft: 30,
          }}
          className="btn"
          onClick={routeNavigate}
        >
          <div>返回主頁</div>
        </Button>
      </div>
    </div>
  );
}
