import React, { useState, useEffect } from "react";
import "../styles/FAQ.css";
import { Accordion, Row, Col, Container } from "react-bootstrap";
import CardViewModal from "../CMSComponent/CardViewModal";
import { FAQData } from "../helper/tempData";
import FAQComp from "../components/FAQComp";
import axios from "../helper/axios";
import { LUA } from "../config";

export default function FAQ() {
  const [CMSMode, setCMSMode] = useState(false);

  const [CMSHover, setCMSHover] = useState(false);

  const [showCardViewModal, setShowCardViewModal] = useState(false);
  const [showCKModal, setShowCKModal] = useState(false);

  const [fqaCMSData, setFapCMSData] = useState([]);

  const handleMouseIn = () => {
    setCMSHover(true);
  };

  const handleMouseOut = () => {
    setCMSHover(false);
  };

  useEffect(() => {
    getData();
    if (sessionStorage.getItem("CMSMode")) {
      setCMSMode(true);
    }
  }, []);

  const getData = async () => {
    const luaToken = LUA.luaToken;
    let apiResponse;

    const post = {
      token: luaToken,
    };

    // if (FAQData.success) {
    //   setFapCMSData(FAQData.result);
    //   console.log(FAQData);
    // }

    axios.post("/get_dc_qna.php", post).then((response) => {
      apiResponse = response.data;
      console.log(apiResponse);

      if (response.data.success) {
        setFapCMSData(response.data.result);
      }
    });
    return;
  };

  const fetchData = fqaCMSData.map((array, index) => {
    if (fqaCMSData.length > 0) {
      if (array.active) return <FAQComp key={index} data={array} />;
    }
  });

  return (
    <div className="FAQ">
      <div
        className="FAQbackground"
        // style={{
        //   backgroundImage: `url(${targetbanner})`,
        // }}
      />
      {/* <img
        src={require("../img/png/FAQ-background.png")}
        alt="Banner"
        width={"100%"}
        height={"100%"}
      /> */}
      <div className="content">
        {showCardViewModal ? (
          <CardViewModal
            apiData={fqaCMSData}
            location={"FAQ"}
            show={showCardViewModal}
            handleClose={() => {
              setShowCardViewModal(false);
              window.location.reload();
            }}
          />
        ) : null}
        <Container
          className={CMSMode ? "content-border" : null}
          onMouseOver={() => {
            if (CMSMode) {
              handleMouseIn();
            }
          }}
          onMouseOut={() => {
            if (CMSMode) {
              handleMouseOut();
            }
          }}
        >
          {CMSHover ? (
            <button
              className="cms-edit-btn"
              onClick={() => {
                setShowCardViewModal(true);
              }}
            >
              文字編輯
            </button>
          ) : null}

          <Row className="justify-content-center">
            <Col className="col-md-6">
              <Accordion flush className="flush">
                {fetchData}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
