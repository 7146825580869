import _axios from "axios";
import { Config } from "../envConfig";

const axios = (baseURL) => {
  //建立一個自定義的axios
  const instance = _axios.create({
    baseURL: baseURL || "https://dc.luahk.org/" + Config.path, //JSON-Server端口位置
    timeout: 5000,
  });

  return instance;
};

export { axios };
export default axios();
