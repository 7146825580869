import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import CKEditorComp from "./CKEditor";
import "../styles/CKModal.css";

export default function CKEditorModal(props) {
  const [data, setData] = useState(props.data);
  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 999999 }}
      show={props.show}
    >
      <Modal.Header>
        <h2>修改 文字設定</h2>
      </Modal.Header>
      <Modal.Body>
        {props.postion === "FAQ" ? (
          <>
            <CKEditorComp
              typeOfCK={props.type == "edit" ? null : "add"}
              title={"問題"}
              data={props.type == "add" ? null : data.title}
              stateData={(newValue) => {
                setData((prevState) => ({
                  ...prevState,
                  title: newValue,
                }));
                console.log(data);
              }}
            />
            <CKEditorComp
              typeOfCK={props.type == "edit" ? null : "add"}
              title={"答案"}
              data={props.type == "add" ? null : data.content}
              stateData={(newValue) => {
                setData((prevState) => ({
                  ...prevState,
                  content: newValue,
                }));
              }}
            />
          </>
        ) : null}
        {props.postion === "dcmain" ? (
          <>
            <CKEditorComp
              typeOfCK={props.type == "edit" ? null : "add"}
              title={"標題"}
              data={props.type == "add" ? null : props.data.content}
            />
          </>
        ) : null}
        <Button
          variant="primary"
          onClick={() => {
            if (props.type == "edit") {
              console.log("edit");
              props.postData(data);
              props.handleClose();
            }
            if (props.type == "add") {
              console.log("add");
              const temp = { ...data, ordinal: 1, active: true };
              props.postData(temp);
              props.handleClose();
            }
          }}
          disabled={data === null ? true : false}
        >
          儲存
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          返回
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
