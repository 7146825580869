import React, { useState, useEffect } from "react";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { Card, Button, Col } from "react-bootstrap";
import { arrayMoveImmutable } from "array-move";

export default function SortableComp(props) {
  const SortableItem = sortableElement(({ value, img }) => (
    <Card
      style={{
        width: "18rem",
        height: img ? "15rem" : "8rem",
        borderWidth: 1,
        boxShadow: "0px 2px 0px #9E9E9E",
        zIndex: 999999,
        listStyle: "none",
      }}
    >
      <li>
        <div dangerouslySetInnerHTML={{ __html: value }} />
        {img ? (
          <img src={img} style={{ width: "40%", height: "100%" }} />
        ) : null}
      </li>
    </Card>
  ));

  const SortableContainer = sortableContainer(({ children }) => {
    return <ul style={{ listStyle: "none" }}>{children}</ul>;
  });

  const [items, setItems] = useState([...props.data]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = arrayMoveImmutable(items, oldIndex, newIndex);
    let tempArr = [];
    for (let n = 0; n < newItems.length; n++) {
      const temp = { ...newItems[n], ordinal: n };
      console.log("newItems", temp);
      tempArr.push(temp);
    }
    setItems(tempArr);
    props.setNewData(tempArr);
  };
  return (
    <SortableContainer onSortEnd={onSortEnd}>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value.id}`}
          index={index}
          value={value.title}
          img={value.image}
        />
      ))}
    </SortableContainer>
  );
}
