import React from "react";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import "../styles/aboutus.css";
import { Container, Row, Col } from "react-bootstrap";
import { ReactComponent as AboutUsIcon } from "../img/svg/aboutusIcon.svg";
import { ReactComponent as LUAIcon } from "../img/svg/LUAIcon.svg";
import { ReactComponent as LocationIcon } from "../img/svg/location_icon.svg";
import { ReactComponent as Telephone } from "../img/svg/phone.svg";
import { ReactComponent as Fax } from "../img/svg/fax.svg";
import { ReactComponent as Email } from "../img/svg/mail.svg";
import GoogleMapCom from "../components/GoogleMapCom";

const containerStyle = {
  width: "60%",
  height: "407px",
};

const center = {
  lat: 22.28675,
  lng: 114.1921,
};

export default function AboutUs() {
  const [map, setMap] = React.useState(null);

  const onLoad = (marker) => {
    console.log("marker: ", marker);
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <>
      <Container className="div-all font">
        <Row className="gap-lg-0 gap-sm-5 gap-5">
          <Col
            xs={{ span: 10, offset: 1 }}
            sm={{ span: 12, offset: 0 }}
            lg={{ span: 6, offset: 0 }}
          >
            <GoogleMapCom />
          </Col>
          <Col
            xs={{ span: 10, offset: 1 }}
            sm={{ span: 12, offset: 0 }}
            lg={{ span: 6, offset: 0 }}
          >
            {/* <Container> */}
            <Row>
              <Col className="iconplustext">
                <AboutUsIcon className="AboutUsIcon" />
                <div style={{ marginLeft: 20 }}>
                  <div className="dc-header">Directors’ Club 總監專用熱線:</div>
                  <div className="dc-num">6282 8869</div>
                </div>
              </Col>
            </Row>
            <Row className="row-flex">
              <Col xs={12}>
                <div className="iconplustext">
                  <LUAIcon className="LUAICON" />
                  <div style={{ marginLeft: 20 }}>
                    <div className="lua-title">香港人壽保險從業員協會</div>
                    <div className="lua-title">
                      The Life Underwriters Association of Hong Kong (LUAHK)
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row
              style={{ flexWrap: "nowrap" }}
              className="row-flex row-flex-cloumn"
            >
              <div className="row-flex-row">
                <LocationIcon style={{ width: "auto", paddingRight: "8px" }} />
                <div className="icon-title">地址 Address</div>
              </div>
              <div>
                <div className="icon-content">
                  Unit A-D, 23/F., Seabright Plaza, 9~23 Shell Street, North
                  Point, Hong Kong.
                  <br />
                  香港北角蜆殼街9-23號秀明中心23樓A-D室
                </div>
              </div>
            </Row>
            <Row style={{ flexWrap: "nowrap" }} className="row-flex">
              <Col>
                <div className="row-flex-row">
                  <Telephone style={{ width: "auto", paddingRight: "8px" }} />
                  <div className="icon-title">電話 Tel</div>
                </div>
                <div>
                  <div className="icon-content">2570 2256</div>
                </div>
              </Col>
              <Col>
                <div className="row-flex-row">
                  <Fax style={{ width: "auto", paddingRight: "8px" }} />
                  <div className="icon-title">傳真 Fax</div>
                </div>
                <div>
                  <div className="icon-content">2570 1525</div>
                </div>
              </Col>
            </Row>
            <Row
              style={{ flexWrap: "nowrap" }}
              className="row-flex row-flex-cloumn"
            >
              <div className="row-flex-row">
                <Email style={{ width: "auto", paddingRight: "8px" }} />
                <div className="icon-title">電郵 Email</div>
              </div>
              <div>
                <div className="icon-content">info@luahk.org</div>
              </div>
            </Row>
            {/* </Container> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}
