const VERSION = "v1.3";

export const LUA = {
  fbToken: null,
  appToken: null,

  //dev
  // mdsEndpoint: "mdsawsdev.surpasstailor.com/stems_api",
  // // mdsEndpoint: "dc.luahk.org/UAT/mds_api.php",
  // mdsToken:
  //   "mCBrTJ1zr4nMWgOU4KbaVzge8FvBaxL0ucWlXs5n8bXxQkkyhpZSIHjiCA0TINdDt2RMflhp7YEQH6Y1JsdEPc9f",

  // luaEndpoint: "www.luahk.org/DEV/api",
  // luaToken:
  //   "2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTY0NjYyMDI5MCwiaWF0IjoxNjQ2NjIwMjkwfQ",
  // envSetting: "DEV",

  //uat
  // mdsEndpoint: "mdsawsuat.surpasstailor.com/stems_api",
  // mdsToken:
  //   "jwxMvBiITUks0cTq2EiFdluKemYpGALP6RUDo6eC1EN8rfVp5tKHD0ZYSMhuocGQnJgXvXza3tnfNl7975VI8ysg",

  // luaEndpoint: "www.luahk.org/UAT/api",
  // luaToken:
  //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzc3N1YiI6IjEyYWZhZmFmYTkwIiwibndhYXdmbWUiOiJKb2hhZmEsbGo0b2UiLCJhZG0zNWluIjoiYXJhcmlpIn0",
  // envSetting: "UAT",

  //prod
  mdsEndpoint: "mdsawsprod.surpasstailor.com/stems_api",
  mdsToken:
    "VzBNHkfJohOgmhUZOj7eEulqvv49QYypNWx0itqKMcdXA6I8eS5cRdiXF94mCGauWC8LBr3fbpPFk2n0KQsEgowI",

  luaEndpoint: "www.luahk.org/api",
  luaToken:
    "wiYXVkIjoid3d3LmV4YWlN1cm5hbWUiOiJSbnZXIiLCJQcm9qZWN0IEFk2NrZXQ1wbGUAjt2eqY2tlad42dEBleGFViIjoianJvY2tldEBleGFtcGxtcGxl2RG3Haf354adH43F",
  envSetting: "PROD",
};
