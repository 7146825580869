import React, { useEffect, useRef } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import "../styles/googlemap.css"

const mapHeight = window.innerHeight

const containerStyle = {
  width: "782px",
  height: "407px",
};

const center = {
  lat: 22.28675,
  lng: 114.1921,
};

function GoogleMapCom() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCl03BytBMIaVEVt1w8--xp6PK18-AkzLw",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <div 
    style={{ height: '100%', 
    width: '100%', 
    display: 'flex', 
    flexFlow: 'row nowrap', 
    justifyContent: 'center',
    alignItems: 'center',}}
    >
        
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={16}
      className="google-map"
      // onLoad={onLoad}
      // onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <Marker position={{ lat: 22.28675, lng: 114.1921 }} />
    </GoogleMap>
    </div>
  ) : (
    <></>
  );
}

export default React.memo(GoogleMapCom);
