import React, { useState, useEffect, useRef } from "react";

import { Alert, Button, Form } from "react-bootstrap";
import "../styles/loginform.css";
import { ReactComponent as Info } from "../img/svg/info.svg";
import InfoModal from "./InfoModal";
import { mdsAPI } from "../helper/apiConfig";
import { LUA } from "../config";
import ChooseInput from "./loginInput/ChooseInput";
import axios from "../helper/axios";
import { useNavigate } from "react-router-dom";
import { memberInfo } from "../helper/memberInfo";
import AlertModal from "./AlertModal";
import { Config } from "../envConfig";
import { DCmember } from "../helper/memberInfo";

export default function LoginForm(props) {
  const timer = useRef();
  const [inputMethod, setInputMethod] = useState("");

  const [postID, setPostID] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const [memberNum, setMemberNum] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [email, setEmail] = useState("");

  const [showAlert, setShowAlert] = useState(false);

  const [selectAuth, setSelectAuth] = useState("HKID");

  const [showAlertModal, setShowAlertModal] = useState(false);

  const [inputID, setInputID] = useState(true);

  let navigate = useNavigate();
  const routeNavigate = () => {
    if (props.disable) {
      let path = Config.path + "/Uploadphoto";
      navigate(path);
    } else {
      let path = Config.path + "/";
      navigate(path);
      window.location.reload(false);
    }
  };

  const refreshCom = () => {
    setInputID(false);
    console.log("refreshCom");
    let temp = [];
    setPostID(temp);
    timer.current = setInterval(() => {
      setInputID(true);
    }, 500);
  };

  const postData = async () => {
    const mdsToken = LUA.mdsToken;
    let apiResponse;
    const post = {
      token: mdsToken,
      phone_num: phoneNum,
      email: email,
      member_num: memberNum,
      auth_method: selectAuth,
      id_num: postID,
    };
    console.log("log", post);

    if (props.disable) {
      axios.post("/mds_api.php", post).then((response) => {
        apiResponse = response.data;
        console.log("axios", response);
        if (apiResponse.success) {
          if (apiResponse.data.show_alert) {
            // console.log("goood");
            let temp = [];
            setPostID(temp);
            setPhoneNum("");
            setMemberNum("");
            setEmail("");
            alert(apiResponse.message);
            sessionStorage.setItem(
              "memberNum",
              apiResponse.data.member_info.member_num
            );
            memberInfo.memberNum = sessionStorage.getItem("memberNum");
            routeNavigate();
          } else {
            console.log("goood");
            let temp = [];
            setPostID(temp);
            setPhoneNum("");
            setMemberNum("");
            setEmail("");
            // alert(apiResponse.message);
            sessionStorage.setItem(
              "memberNum",
              apiResponse.data.member_info.member_num
            );
            memberInfo.memberNum = sessionStorage.getItem("memberNum");
            routeNavigate();
          }
        } else {
          alert(apiResponse.message);
        }
      });
    } else {
      // sessionStorage.setItem("memberNum", 19730001);
      // memberInfo.checkLogedin = true;
      // return;
      axios.post("/login_dc.php", post).then((response) => {
        apiResponse = response.data;
        console.log("axios", response);
        if (apiResponse.success) {
          if (apiResponse.data.show_alert) {
            // console.log("goood");
            let temp = [];
            setPostID(temp);
            setPhoneNum("");
            setMemberNum("");
            setEmail("");
            alert(apiResponse.message);
            sessionStorage.setItem(
              "memberNum",
              apiResponse.data.member_infos.member_num
            );
            memberInfo.memberNum = sessionStorage.getItem("memberNum");
            routeNavigate();
          } else {
            console.log("goood");
            let temp = [];
            setPostID(temp);
            setPhoneNum("");
            setMemberNum("");
            setEmail("");
            // alert(apiResponse.message);
            sessionStorage.setItem(
              "memberNum",
              apiResponse.data.member_infos.member_num
            );
            memberInfo.checkLogedin = true;
            memberInfo.memberNum = sessionStorage.getItem("memberNum");
            sessionStorage.setItem(
              "memberInfo",
              JSON.stringify(apiResponse.data.member_infos)
            );
            routeNavigate();
          }
        } else {
          alert(apiResponse.message);
        }
      });
    }
  };

  useEffect(() => {
    setInputMethod("memberNum");
  }, []);

  useEffect(() => {
    refreshCom();
  }, [phoneNum, memberNum, email]);

  return (
    <div className="font login-from-style">
      {showModal ? (
        <InfoModal
          show={showModal}
          handleClose={() => {
            setShowModal(false);
          }}
        />
      ) : null}

      {showAlertModal ? (
        <AlertModal
          show={showAlertModal}
          handleClose={() => {
            setShowAlertModal(false);
          }}
        />
      ) : null}

      <div className="login-input">輸入方式:</div>
      <Form>
        <div className="login-input-method">
          <div className="div-login-input">
            <Button
              variant="flat"
              onClick={(e) => {
                setInputMethod("phoneNum");
                e.target.classList.add("active");
                console.log("e", e.target);
                refreshCom();
                setEmail("");
                setMemberNum("");
              }}
            >
              電話號碼
            </Button>
          </div>
          <div className="div-login-input">
            <Button
              variant="flat"
              onClick={() => {
                refreshCom();
                setInputMethod("memberNum");
                setEmail("");
                setPhoneNum("");
              }}
            >
              會員號碼
            </Button>
          </div>

          <div className="div-login-input">
            <Button
              variant="flat"
              onClick={() => {
                refreshCom();
                setInputMethod("email");
                setPhoneNum("");
                setMemberNum("");
              }}
            >
              電郵地址
            </Button>
          </div>
        </div>
        {inputMethod === "phoneNum" ? (
          <Form.Control
            type="text"
            placeholder="請在這裹輸入你的電話號碼"
            name="username"
            value={phoneNum}
            onChange={(e) => {
              setPhoneNum(e.target.value);
              console.log("e", phoneNum);
            }}
          />
        ) : null}
        {inputMethod === "memberNum" ? (
          <Form.Control
            type="text"
            placeholder="請在這裹輸入你的會員號碼"
            name="username"
            value={memberNum}
            onChange={(e) => {
              setMemberNum(e.target.value);
              console.log("e", phoneNum);
            }}
          />
        ) : null}
        {inputMethod === "email" ? (
          <Form.Control
            type="text"
            placeholder="請在這裹輸入你的電郵地址"
            name="username"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              console.log("e", phoneNum);
            }}
          />
        ) : null}

        <div className="div-auth-method">
          <div className="login-sml-title">選擇您的身份證並輸入其號碼：</div>
          <Info
            className="info-icon"
            onClick={() => {
              setShowModal(true);
              console.log("pressed");
            }}
          />
        </div>

        <div className="div-choose-id">
          <div className="login-sml-title login-sml-padding">身份證驗證：</div>
          <div className="div-select-method">
            <Form.Select
              value={selectAuth}
              onChange={(e) => {
                console.log("e.target.value", e.target.value);
                setSelectAuth(e.target.value);
                let temp = [];
                setPostID(temp);
              }}
            >
              <option value="HKID">香港特別行政區身份證</option>
              <option value="MOID">澳門特別行政區身份證</option>
              <option value="CNID">中華人民共和國身份證</option>
            </Form.Select>
          </div>
        </div>
        <div className="div-input-box">
          {inputID === true ? (
            <ChooseInput
              selectAuth={selectAuth}
              postID={postID}
              setPostID={setPostID}
            />
          ) : null}
        </div>
        <div className="div-btn">
          <Button
            style={{
              width: "70%",
              height: 50,
              backgroundColor: props.color,
              border: 0,
              borderRadius: 8,
            }}
            onClick={() => {
              postData();
              // routeNavigate();
            }}
          >
            登入
          </Button>
        </div>
      </Form>
    </div>
  );
}
