import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Config } from "../envConfig";
import axios from "../helper/axios";
import "../styles/modal.css";
import { LUA } from "../config";

export default function Cmslogin(props) {
  let navigate = useNavigate();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const nav = () => {
    let path = Config.path + "/dcpage/cms";
    navigate(path);
    window.location.reload(false);
  };

  const homeNav = () => {
    let path = Config.path;
    navigate(path);
    window.location.reload(false);
  };
  const checkLogin = () => {
    let apiResponse;
    const luaToken = LUA.mdsToken;

    const post = {
      token: luaToken,
      email: username,
      password: password,
    };
    axios.post("/cmslogin.php", post).then((response) => {
      apiResponse = response.data;
      console.log(apiResponse);

      if (response.data.success) {
        console.log("success");
        sessionStorage.setItem("CMSMode", true);
        nav();
      } else {
        alert(response.data.message);
      }
    });
  };
  return (
    <Modal style={{ zIndex: 999999 }} show={true} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>CMS System</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>Username</div>
        <input
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <div>Password</div>
        <input
          type={"password"}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={homeNav}>
          Close
        </Button>
        <Button variant="primary" onClick={checkLogin}>
          Login
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
