import { render } from "@testing-library/react";
import React, { useState, useEffect, useRef } from "react";
import "../../styles/loginform.css";

export default function ChooseInput(props) {
  // const [postID, setPostID] = useState([]);

  const [authMethod, setAuthMethod] = useState([]);
  const [randomNum, setRandomNum] = useState([]);

  useEffect(() => {
    console.log("useEffect");
    if (props.selectAuth === "HKID") {
      console.log("props,HKID");
      genRandomNum(7, 4);
      setRandomNum(genRandomNum(7, 4));
      loopArray(7);
      props.setPostID([]);
    } else if (props.selectAuth === "MOID") {
      console.log("props,MOID");
      genRandomNum(8, 5);
      setRandomNum(genRandomNum(8, 5));
      loopArray(8);
      props.setPostID([]);
    } else if (props.selectAuth === "CNID") {
      console.log("props,CNID");
      genRandomNum(18, 5);
      setRandomNum(genRandomNum(18, 5));
      loopArray(18);
      props.setPostID([]);
    }
  }, [props.selectAuth]);

  //set diff num
  const genRandomNum = (num, randanNum) => {
    let numArr = [];
    let tempNum = randanNum;
    let i = 0;
    let numInput = num;
    //console.log("genRan", numInput);
    while (numArr.length < numInput) {
      i++;
      if (i < tempNum) {
        numArr.push(true);
      } else {
        numArr.push(false);
      }
    }
    //console.log("shuffle", numArr);
    shuffle(numArr);
    //console.log("shuffled", numArr);
    return numArr;
  };

  //insert
  const loopArray = (num) => {
    let tempArray = [];
    let arrayLength = num;
    tempArray.length = arrayLength;
    for (let i = 0; i < tempArray.length; i++) {
      tempArray[i] = i;
    }
    setAuthMethod(tempArray);
  };

  //shuffle
  function shuffle(array) {
    for (let i = array.length - 1; i > 4; i--) {
      const j = Math.floor(Math.random() * i);
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  const RenderInput = authMethod.map((array, index) => {
    if (randomNum.length > 0) {
      if (randomNum[array] == true) {
        return (
          <>
            <input
              style={{ marginTop: "5px" }}
              className="input-box"
              type="text"
              maxlength="1"
              value={props.postID[index]}
              onChange={(e) => {
                let oldPostID = props.postID;
                oldPostID[index] = e.target.value;
                props.setPostID(oldPostID);
                // console.log(e);
                // console.log("props", props.postID);
              }}
              onKeyUp={(e) => {
                const form = e.target.form;
                const index = [...form].indexOf(e.target);
                console.log("index", index);
                if (e.target.value != "") {
                  if (e.key.toLocaleLowerCase() != "backspace") {
                    form.elements[index + 1].focus();
                    e.preventDefault();
                  }
                }
              }}
              onKeyDown={(e) => {
                const form = e.target.form;
                const index = [...form].indexOf(e.target);
                console.log("index", index);
                if (e.target.value == "") {
                  // console.log("check");
                  if (e.key.toLocaleLowerCase() == "backspace") {
                    form.elements[index - 1].focus();
                    e.preventDefault();
                  }
                }
              }}
            ></input>
          </>
        );
      }
      if (randomNum[array] == false) {
        return (
          <div style={{ marginTop: "5px" }} className="div-dis-square"></div>
        );
      }
    }
  });

  const RenderInputHK = authMethod.map((array, index) => {
    if (randomNum.length > 0) {
      if (randomNum[array] == true) {
        return (
          <>
            {index == 6 ? <div style={{ fontSize: "22px" }}>(</div> : null}
            <input
              className="input-box"
              type="text"
              maxlength="1"
              value={props.postID[index + 2]}
              onChange={(e) => {
                let oldPostID = props.postID;
                oldPostID[index + 2] = e.target.value;
                props.setPostID(oldPostID);
                // console.log(e);
                // console.log("props", props.postID);
              }}
              onKeyUp={(e) => {
                const form = e.target.form;
                const index = [...form].indexOf(e.target);
                // console.log("index", index);
                if (e.target.value != "") {
                  if (e.key.toLocaleLowerCase() != "backspace") {
                    form.elements[index + 1].focus();
                    e.preventDefault();
                  }
                }
              }}
              onKeyDown={(e) => {
                const form = e.target.form;
                const index = [...form].indexOf(e.target);
                // console.log("index", index);
                if (e.target.value == "") {
                  // console.log("check");
                  if (e.key.toLocaleLowerCase() == "backspace") {
                    form.elements[index - 1].focus();
                    e.preventDefault();
                  }
                }
              }}
            ></input>
            {index == 6 ? <div style={{ fontSize: "22px" }}>)</div> : null}
          </>
        );
      }
      if (randomNum[array] == false) {
        return (
          <>
            {index == 6 ? <div style={{ fontSize: "22px" }}>(</div> : null}
            <div className="div-dis-square"></div>
            {index == 6 ? <div style={{ fontSize: "22px" }}>)</div> : null}
          </>
        );
      }
    }
  });
  return (
    <>
      {props.selectAuth === "HKID" ? (
        <>
          <div>
            <div className="div-input-name">
              <div className="div-input-eng">
                <div>英文</div>
                <div className="input-eng-border"></div>
              </div>
              <div className="div-input-int">
                <div className="div-int-font">數字</div>
                <div className="input-int-border"></div>
              </div>
              <div className="div-input-brackets">
                <div className="input-brackets-font">括號</div>
                <div className="input-brackets-border"></div>
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <input
                style={{ marginLeft: "5px" }}
                className="input-box"
                type="text"
                maxlength="1"
                value={props.postID[0]}
                onChange={(e) => {
                  let oldPostID = props.postID;
                  oldPostID[0] = e.target.value;
                  props.setPostID(oldPostID);
                  // console.log(e);
                  // console.log("props", props.postID);
                }}
                onKeyUp={(e) => {
                  const form = e.target.form;
                  const index = [...form].indexOf(e.target);
                  // console.log("index", index);
                  if (e.target.value != "") {
                    if (e.key.toLocaleLowerCase() != "backspace") {
                      form.elements[index + 1].focus();
                      e.preventDefault();
                    }
                  }
                }}
                onKeyDown={(e) => {
                  const form = e.target.form;
                  const index = [...form].indexOf(e.target);
                  // console.log("index", index);
                  if (e.target.value == "") {
                    // console.log("check");
                    if (e.key.toLocaleLowerCase() == "backspace") {
                      form.elements[index - 1].focus();
                      e.preventDefault();
                    }
                  }
                }}
              ></input>
              <div className="div-dis-square"></div>
              <div className="div-margin"></div>
              {RenderInputHK}
            </div>
          </div>
        </>
      ) : null}
      {props.selectAuth === "MOID" ? (
        <>
          <div>
            <div className="div-input-int">
              <div style={{ marginLeft: "0px" }} className="div-int-font">
                數字
              </div>
              <div
                style={{ width: "auto", marginLeft: "0px" }}
                className="input-int-border"
              ></div>
            </div>
            <div
              style={{
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {RenderInput}
            </div>
          </div>
        </>
      ) : null}
      {props.selectAuth === "CNID" ? (
        <>
          <div>
            <div className="div-input-int">
              <div style={{ marginLeft: "0px" }} className="div-int-font">
                數字
              </div>
              <div
                style={{ width: "auto", marginLeft: "0px" }}
                className="input-int-border"
              ></div>
            </div>
            <div
              style={{
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                flexWrap: "wrap",
                width: "400px",
              }}
            >
              {RenderInput}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
