export let memberInfo = {
  memberNum: null,
  memberNameEN: null,
  memberNameCH: null,
  memberGengre: null,
  memberYear: null,
  memberEntry: null,
  checkLogedin: false,
};

export const DCnumbering = {
  DCnumbering: null,
};

export const DCmember = {};
