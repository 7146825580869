import React from "react";
import "../styles/joindc.css";
import background from "../img/png/dc_step_bg.png";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ReactComponent as DCIcon } from "../img/svg/aboutusIcon.svg";
import { ReactComponent as Member } from "../img/svg/member.svg";
import { ReactComponent as Holding } from "../img/svg/holding.svg";
import { useNavigate } from "react-router-dom";
import { Config } from "../envConfig";

export default function JoinDirector() {
  let navigate = useNavigate();
  const routeNavigate = () => {
    let path = Config.path + "/loginLUA";
    navigate(path);
  };
  return (
    <div className="joindc-container">
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "auto",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        <Container>
          <Row
            style={{
              backgroundColor: "white",
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <Col
              xxl={"12"}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 90,
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <DCIcon />
              <p className="joindc-font-title div-font">加入保協總監會的要求</p>
            </Col>
            <Col
              lg={"4"}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <img
                src={require("../img/png/become_mem_new.png")}
                alt="Banner"
                width={"100%"}
                height={"100%"}
              />
            </Col>
            <Col
              lg={"4"}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <img
                src={require("../img/png/holding_new.png")}
                alt="Banner"
                width={"100%"}
                height={"100%"}
              />
            </Col>
              <Col
                  lg={"4"}
                  style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingLeft: 0,
                      paddingRight: 0,
                  }}
              >
                  <img
                      src={require("../img/png/Approved.png")}
                      alt="Banner"
                      width={"100%"}
                      height={"100%"}
                  />
              </Col>
          </Row>
        </Container>
        <Container>
          <Row
            style={{
              backgroundColor: "white",
              marginTop: 40,
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <Col
              xxl={"12"}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: 0,
                height: 90,
                paddingRight: 0,
              }}
            >
              <DCIcon />
              <p className="joindc-font-title div-font">
                Directors’ Club 會員申請三部曲
              </p>
            </Col>
            <Col
              lg={"4"}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <img
                src={require("../img/png/login.png")}
                alt="Banner"
                width={"100%"}
                height={"100%"}
              />
            </Col>
            <Col
              lg={"4"}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <img
                src={require("../img/png/upload.png")}
                alt="Banner"
                width={"100%"}
                height={"100%"}
              />
            </Col>
            <Col
              lg={"4"}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <img
                src={require("../img/png/comfirm.png")}
                alt="Banner"
                width={"100%"}
                height={"100%"}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="div-btn">
        <Button
          style={{
            width: 135,
            height: 50,
            backgroundColor: "#7D6746",
            border: 0,
            borderRadius: 8,
          }}
          className="btn"
          onClick={routeNavigate}
        >
          <div className="div-btn-font">立即申請</div>
        </Button>
      </div>
    </div>
  );
}
