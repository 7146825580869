import React from "react";
import "../styles/homepage.css";
import { Col, Container, Row, Image } from "react-bootstrap";
import AboutUs from "./AboutUs";

export default function Homepage() {
  return (
    <div className="homepage homepage-font">
      <div
          className="homepagebanner"
          // style={{
          //   backgroundImage: `url(${targetbanner})`,
          // }}
      />
      {/* <img
        src={require("../img/png/Homepage-banner.png")}
        className="homepagebanner"
        alt="Banner"
        width={"100%"}
        height={"100%"}
      /> */}
      <div className="hp-margin-top" />
      <Container>
        <Row className="gap-5 reverse">
          <Col style={{ width: 635 }}>
            <div className="header" style={{ display: "flex", marginLeft: 15 }}>
              <p style={{ marginBottom: 45 }}>關於Directors’ Club</p>
            </div>
          </Col>
        </Row>
        <Row className="gap-5 reverse ">
          <Col>
            <p className="content">
              為凝聚業界力量，推動同業邁向更專業發展，「保協」成立「總監會」（LUA
              Directors’
              Club），誠邀各保險公司的總監成為會員，藉此向大家提供不同的支援，包括:
              總監專用熱線，申請牌照支援，免費法律援助等等，幫助大家帶領團隊邁向成功。
            </p>
            <p className="content">
              保協期望透過「總監會」構建的平台，可以加強業界的互動溝通；另外，由於一眾總監均為業界領袖，通過彼此的交流將有助滙聚行業領袖的意見，讓保協能更精準地把同業的意見向當局反映。​
            </p>
            <p className="content">
              我們鼓勵各大保險公司的總監成為「總監會」的一員，透過分享彼此的知識和經驗，推動行業朝更專業、更創新的方向發展。
            </p>
          </Col>
          <Col className="img-postion">
            <img
              src={require("../img/png/strength-people.png")}
              alt="strength-people"
              className="pplimg"
            />
          </Col>
        </Row>
      </Container>

      <div className="hp-margin-top">
        <div className="header center">Directors’ Club設立的目標</div>
      </div>
      <div>
        <Container style={{ flexDirection: "row" }}>
          <Row className="justify-content-center">
            <Col className="icon-padding col-xxl-2 col-sm-3 col-xs-1">
              <img
                src={require("../img/png/communicate.png")}
                alt="Banner"
                width={120}
                height={120}
              />
              <p className="content-margin-top  icontext">提升業界互動溝通</p>
            </Col>
            <Col className="icon-padding col-xxl-2 col-sm-3 col-xs-1">
              <img
                src={require("../img/png/chat.png")}
                alt="Banner"
                width={120}
                height={120}
              />
              <p className="content-margin-top icontext">匯聚業界​領袖意見</p>
            </Col>
            <Col className="icon-padding col-xxl-2 col-sm-3 col-xs-1">
              <img
                src={require("../img/png/develop.png")}
                alt="Banner"
                width={120}
                height={120}
              />
              <p className="content-margin-top  icontext">協助業界團隊發展</p>
            </Col>
            <Col className="icon-padding col-xxl-2 col-sm-3 col-xs-1">
              <img
                src={require("../img/png/spirit.png")}
                alt="Banner"
                width={120}
                height={120}
              />
              <p className="content-margin-top icontext">共享保險​助人精神</p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="hp-margin-top">
        <div
          className="targetbanner"
          // style={{
          //   backgroundImage: `url(${targetbanner})`,
          // }}
        />
        {/* <Image
            src={require("../img/png/target-banner.png")}
            alt="Banner"
            
            className="mx-auto d-block"
          /> */}
      </div>
      <AboutUs />
    </div>
  );
}
