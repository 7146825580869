import { LUA } from "../config";

export const readedemail = async () => {
  return {
    data: [
      { title: "hello", content: "你好, 你是陳大明", time: "25分鐘前" },

      { title: "What", content: "你好, 她是陳大明", time: "2020年1月2日" },
    ],
  };
};

export const mdsAPI = async (api, data) => {
  let res = await MDS_API.post(api, data);

  return res;
};

export const MDS_API = {
  async post(uri, data) {
    let response, text;
    let url = "http://" + LUA.mdsEndpoint + "/" + uri;
    console.log("api_endpoint:::", url);
    try {
      response = await MDS_API.fetchWithTimeout(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      text = await response.text();
      return JSON.parse(text);
    } catch (e) {
      console.log("lgoin::::", e);
      return {
        success: false,
        message: "NETWORK_ERROR",
        data: {},
      };
    }
  },
  async fetchWithTimeout(resource, options = {}) {
    const { timeout = 3000 } = options;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);
    return response;
  },
};

export const luaAPI = async (api, data) => {
  let res = await LUA_API.post(api, data);
  return res;
};

export const LUA_API = {
  async post(uri, data) {
    let response, text;
    let url = "https://" + LUA.luaEndpoint + "/" + uri;
    //console.log('api_endpoint:::', url);
    try {
      response = await LUA_API.fetchWithTimeout(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      text = await response.text();
      return JSON.parse(text);
    } catch (e) {
      console.log("api::::", e);
      return {
        success: false,
        message: "NETWORK_ERROR",
        data: {},
      };
    }
  },
  async fetchWithTimeout(resource, options = {}) {
    const { timeout = 3000 } = options;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);
    return response;
  },
};
